<template>
  <div class="frame-container p3l2" v-viewer.static>
    <section
      name="p3l2" 
      :class="{
        'w-100 h-100 d-flex': isDesktop,
      }">
      <template v-if="isDesktop">
        <div class="image-container">
          <transition-group name="slide-fade">
            <img width="313" height="450" :src="portraits[0]" v-show="slide == 1" key="p1">

            <img width="313" height="450" :src="portraits[3]" v-show="slide == 2" key="p4">
            
            <img width="313" height="450" :src="portraits[6]" v-show="slide == 3" key="p7">
          </transition-group>
        </div>
        <div class="ml-2">
          <div class="d-flex">
            <transition-group name="slide-fade">
              <img width="317" :src="landscapes[0]" v-show="slide == 1" key="l1">
              <img width="155" height="221" class="ml-2" :src="portraits[1]"  v-show="slide == 1" key="p2">

              <img width="317" :src="landscapes[2]" v-show="slide == 2" key="l3">
              <img width="155" height="221" class="ml-2" :src="portraits[4]"  v-show="slide == 2" key="p5">

              <img width="317" :src="landscapes[4]" v-show="slide == 3" key="l5">
              <img width="155" height="221" class="ml-2" :src="portraits[7]"  v-show="slide == 3" key="p8">
            </transition-group>
          </div>
          <div class="d-flex">
            <transition-group name="slide-fade">
                <img width="155" height="221" :src="portraits[2]" v-show="slide == 1" key="p3">
                <img width="317" height="221" class="ml-2" :src="landscapes[1]" v-show="slide == 1" key="l2">

                <img width="155" height="221" :src="portraits[5]" v-show="slide == 2" key="p6">
                <img width="317" height="221" class="ml-2" :src="landscapes[3]" v-show="slide == 2" key="l4">

                <img width="155" height="221" :src="portraits[8]" v-show="slide == 3" key="p9">
                <img width="317" height="221" class="ml-2" :src="landscapes[5]" v-show="slide == 3" key="l6">
            </transition-group>
          </div>
        </div>
      </template>

      <template v-else>
        <div class="image-container">
          <transition-group name="slide-fade">
            <img width="330" height="221" :src="landscapes[0]" key="l1" v-show="slide == 1">

            <img width="330" height="221" :src="landscapes[2]" key="l3" v-show="slide == 2">

            <img width="330" height="221" :src="landscapes[4]" key="l5" v-show="slide == 3">
          </transition-group>
        </div>

        <div class="d-flex justify-content-center">
          <transition-group name="slide-fade">
            <img width="103" height="148" class="mr-1-5" :src="portraits[0]" key="p1" v-show="slide == 1">
            <img width="221" height="149" :src="landscapes[1]" key="l2" v-show="slide == 1">

            <img width="103" height="148" class="mr-1-5" :src="portraits[3]" key="p4" v-show="slide == 2">
            <img width="221" height="149" :src="landscapes[3]" key="l4" v-show="slide == 2">
            
            <img width="103" height="148" class="mr-1-5" :src="portraits[6]" key="p7" v-show="slide == 3">
            <img width="221" height="149" :src="landscapes[5]" key="l6" v-show="slide == 3">
          </transition-group>
        </div>

        <div class="d-flex justify-content-center">
          <transition-group name="slide-fade">
            <img width="162" height="233" class="mr-1-5" :src="portraits[1]" key="p2" v-show="slide == 1">
            <img width="162" height="233" :src="portraits[2]" key="p3" v-show="slide == 1">

            <img width="162" height="233" class="mr-1-5" :src="portraits[4]" key="p5" v-show="slide == 2">
            <img width="162" height="233" :src="portraits[5]" key="p6" v-show="slide == 2">

            <img width="162" height="233" class="mr-1-5" :src="portraits[7]" key="p8" v-show="slide == 3">
            <img width="162" height="233" :src="portraits[8]" key="p9" v-show="slide == 3">
          </transition-group>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  props: ["portraits", "landscapes"],
  data() {
    return {
      slide: 1,
      max_images: {
        portrait: 3,
        landscape: 2
      },
      duration: 10000
    }
  },
  computed: {
    max_slide() {
      return (this.portraits.length + this.landscapes.length) / 5
    }
  },
  methods: {
    refreshImages() {
      setInterval(() => {
        if(this.slide < this.max_slide) this.slide += 1
        else this.slide = 1
      }, this.duration)
    }
  },
  mounted() {
    this.refreshImages()
  }
}
</script>

<style scoped>
.slide-fade-enter-active {
  transition: all .8s ease;
  transition-delay: .8s;
}
.slide-fade-leave-active {
  transition: all 2s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  opacity: 0;
  max-width: 0;
  max-height: 0;
}
</style>