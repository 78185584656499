<template>
  <div class="wrapper">
    <div class="spinner">
      <img
        src="/icons/default/momentospin.png"
        class="spin-logo"
        alt="momento loading"
        width="100"
      />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.wrapper {
  background: rgba(0, 0, 0, 0.183);
  height: 100vh;
  width: 100vw;
}

.spinner {
  position: absolute;
  left: 0;
  margin: auto;
  bottom: 50vh;
  right: 0;
  z-index: 100;
  text-align: center;
}

.spin-logo {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>