<template>
  <div
    id="notes-section"
    class="d-flex align-items-center justify-content-center mt-13"
  >
    <div name="content" class="w-100">
      <Notes text="Para hadirin yang kami hormati dan sayangi. Pihak hotel tidak membolehkan kiriman bunga"/>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      css: {
        ...this.$sections.notes
      }
    }
  }
}
</script>