<template>
  <div 
    class="radio-container"
    :style="styles"  
  >
    <template v-if="label">
      <p
        :class="{
          'mb-3-752': isDesktop,
          'mb-1-252': isMobile
        }"
        :style="{
          fontFamily: labelFontFamily,
          fontSize: isDesktop ? labelFontSize : m_labelFontSize,
          color: labelFontColor
        }"
      >
        {{ label }}
      </p>
    </template>
    <div
      :class="{ 'd-flex': isDesktop }"
    >
      <template v-for="item in radio_items">
        <div :key="item.id">
          <div class="radio-item">
            <input
              type="radio"
              :id="item.id"
              :name="name"
              :value="item.value"
              v-model="selectedValue"
            />
            <label
              :style="{ fontFamily: fontFamilyLabel }"
              :for="item.id"
            >
              {{ item.label }}
            </label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ["radio_items", "name", "label", "selected", "fontSizeLabel", "optionLabelColor", "fontFamilyLabel", "fontFamilyBoldLabel", "radioSize", "radioColor", "labelFontFamily", "labelFontSize", "labelFontColor", "m_fontSizeLabel", "m_labelFontSize", "m_radioSize"],
  watch: {
    selectedValue(val) {
      this.$emit("input", val)
    }
  },
  data() {
    return {
      selectedValue: this.selected || this.radio_items[0].value,
      size: this.radioSize || "20px",
      color: this.radioColor || "#707070",
      labelColor: this.optionLabelColor || "#161616",
      labelSize: this.fontSizeLabel || "14px"
    }
  },
  computed: {
    styles() {
      const desktop = {
        '--fontSizeLabel': this.labelSize,
        '--optionLabelColor': this.labelColor,
        '--radioSize': this.size,
        '--radioColor': this.color,

        '--fontFamilyBoldLabel': this.fontFamilyBoldLabel
      }

      const mobile = {
        '--m-radioSize': this.m_radioSize,
        '--m-fontSizeLabel': this.m_fontSizeLabel,
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  p {
    font-family: poppinsSB;
    font-size: 1rem;
  }
  
  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 7px;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  
  .radio-item label {
    font-size: var(--fontSizeLabel);
    font-weight: normal;
    color: var(--optionLabelColor);
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 4.5px;
    margin: 0 5px 0 0;
    width: var(--radioSize);
    height: var(--radioSize);
    border-radius: 14px;
    border: 2px solid var(--radioColor);
    background-color: transparent;
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 14px;
    width: calc(var(--radioSize) - 4px);
    height: calc(var(--radioSize) - 4px);
    position: absolute;
    top: 8.55px;
    left: 11px;
    content: " ";
    display: block;
    background: var(--radioColor);
  }

  .radio-item input:hover, .radio-item label:hover {
    cursor: pointer;
  }

  .radio-item input[type=radio]:checked ~ label {
    font-family: var(--fontFamilyBoldLabel) !important;
  }
</style>

<style mobile scoped>
  .mobile .radio-item label {
    font-size: var(--m-fontSizeLabel);
  }

  .mobile .radio-item label:before {
    width: var(--m-radioSize);
    height: var(--m-radioSize);
  }
  
  .mobile .radio-item input[type=radio]:checked + label:after {
    width: calc(var(--m-radioSize) - 4px);
    height: calc(var(--m-radioSize) - 4px);
  }
</style>