<template>
  <div class="notes-container p-5" :style="{ backgroundColor: backgroundColor }">
    <BorderedText wide>
      <p class="py-2" :style="{ color: fColor, fontFamily: fFamily, fontSize: fSize}">{{ text }}</p>
    </BorderedText>
  </div>
</template>

<script>
export default {
  props: ["text", "bgColor", "fontColor", "fontFamily", "fontSize"],
  data() {
    return {
      backgroundColor: this.bgColor || "#707070",
      fColor: this.fontColor || "#fff",
      fFamily: this.fontFamily || "poppinsMI",
      fSize: this.fontSize || "1rem"
    }
  }
}
</script>