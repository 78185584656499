var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bote-container",style:(Object.assign({}, {fontFamily: _vm.fontFamily, 
    color: _vm.fontColor},
    _vm.styles))},[(_vm.boteStyle == 'chat')?[_c('div',{staticClass:"bote-chat",style:({fontSize: _vm.fontSizeChat,  borderColor: _vm.borderColor})},[_c('p',{staticClass:"name"},[_c('b',[_vm._v(_vm._s(_vm.name))])]),_c('p',{staticClass:"comment mt-2-5"},[_vm._v(_vm._s(_vm.comment))])])]:[_c('div',{staticClass:"bote-card",style:({
        fontSize: _vm.isDesktop ? _vm.fontSizeCard : _vm.m_fontSizeCard,
        borderWidth: _vm.borderWidth,
        borderColor: _vm.borderColor,
        maxWidth: _vm.isWide
      })},[(_vm.icon)?[_c('img',{staticClass:"bote-icon",class:{ 'mb-2-5': _vm.isMobile },attrs:{"width":_vm.isDesktop ? _vm.iconWidth : _vm.m_iconWidth,"src":("/icons/" + _vm.iconDir + "/" + _vm.icon)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]:_vm._e(),_vm._t("default")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }