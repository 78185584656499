<template>
  <div
    id="time-section"
    class="d-flex align-items-center justify-content-center"
  >
    <div name="content" class="w-100">
      <section name="title" class="mt-19-5 mb-7-5">
        <Title text="Diselenggarakan" />
      </section>

      <section name="date" class="d-flex justify-content-center">
        <DateCard :type="css.dateCardType" />
      </section>

      <section name="time" class="mt-7-5 d-flex justify-content-center">
        <TimeCard :type="css.timeCardType" />
      </section>

      <!-- <section name="open-calendar" v-if="isMobile" class="mt-7-5 d-flex justify-content-center">
        <Button icon="calendar.svg" text="Catat di Calendar" />
      </section> -->

      <section name="count-down"
        :class="{
          'mt-13': isMobile,
          'mt-35-5': isDesktop
        }"
      >
        <CountDown :date="$weddingData.receptionCountDown" />
      </section>

    </div>
  </div>
</template>

<script>
import ColorWrapper from "@/components/resources/ColorWrapper"
import DateCard from "@/components/sections/time/DateCard"
import TimeCard from "@/components/sections/time/TimeCard"

export default {
  components: { ColorWrapper, DateCard, TimeCard },
  data() {
    return {
      css: {
        ...this.$sections.time
      }
    }
  }
}
</script>

<style mobile scoped>
  .mobile #time-section {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
</style>