var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-card-container d-flex"},[_c('div',{attrs:{"name":"icon"}},[_c('img',{attrs:{"src":_vm.icon}})]),_c('div',{staticClass:"ml-3-752",style:({
      '--code-font-family': _vm.css.codeFontFamily,
      '--code-font-size': _vm.css.codeFontSize,
      '--code-font-color': _vm.css.codeFontColor,
      '--detail-font-family': _vm.css.detailFontFamily,
      '--detail-font-size': _vm.css.detailFontSize,
      '--detail-font-color': _vm.css.detailFontColor,
    }),attrs:{"name":"detail"}},[_c('p',{staticClass:"code d-flex align-items-center"},[_vm._v(_vm._s(_vm.code)),_c('span',{staticClass:"d-flex ml-1"},[_c('img',{attrs:{"src":("/icons/" + _vm.iconDir + "/copy.svg")}})])]),_c('p',{staticClass:"detail"},[_vm._v(_vm._s(_vm.detail))])])])}
var staticRenderFns = []

export { render, staticRenderFns }