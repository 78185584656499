<template>
  <div class="frame-container p5l0" v-viewer>
    <section name="p5l0" class="w-100 h-100 d-flex">
      <div class="image-container" style="width: 313px">
        <img width="100%" height="450" :src="portraits[0]">
      </div>
      <div class="image-container mx-1-752" style="width: 155px">
        <img width="100%" height="222" :src="portraits[1]">
        <img width="100%" height="222" :src="portraits[2]">
      </div>
      <div class="image-container" style="width: 313px">
        <img width="100%" height="450" :src="portraits[3]">
      </div>
      <div class="image-container ml-1-752" style="width: 313px">
        <img width="100%" height="450" :src="portraits[4]">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["portraits", "landscapes"]
}
</script>

<style scoped>
.p5l0 {
  width: 69.731rem;
  max-width: 69.731rem;
}
</style>