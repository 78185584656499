<template>
  <div class="button-container d-flex justify-content-center align-items-center w-100" :style="{backgroundColor: bgColor}">
    <template v-if="icon">
      <img class="button-icon" :src="`/icons/${iconDir}/${icon}`">
    </template>

    <p class="button-text" :style="{fontFamily, fontSize, color: fontColor}">{{ text }}</p>
  </div>
</template>

<script>
export default {
  props: ["icon", "text", "bgColor", "fontColor", "fontFamily", "fontSize"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir
    }
  }
}
</script>

<style scoped>
  .button-container {
    max-width: 15.625rem;
    max-height: 1.875rem;
    padding: 0.938rem 2.5rem;
    border-radius: 1.875rem;
  }

  .button-container:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px 0 rgba(128, 96, 96, 0.2), 0 6px 20px 0 rgba(125, 79, 79, 0.19);
  }

  .button-icon {
    width: 1.875rem;
  }

  .button-text {
    font-family: var(--poppinsM);
    font-size: 1rem;
    padding-left: 0.938rem;
    color: #161616;
  }
</style>

<style mobile scoped>
  .mobile .button-container {
    max-width: 17.5rem;
    max-height: 2.5rem;
    padding: 0.688rem 4.688rem;
    border-radius: 1.2rem;
  }

  .mobile .button-icon {
    width: 1.25rem;
  }

  .mobile .button-text {
    font-size: 0.75rem;
    padding-left: 0.625rem;
  }
</style>