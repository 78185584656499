<template>
  <div 
    class="music-btn-wrapper d-flex justify-content-center"
    :style="{
      backgroundColor: css.backgroundColor ,
      ...styles
    }"
    @click="changeMusicState()"
  >
    <img :width="isDesktop ? css.iconWidth : css.m_iconWidth" :src="`/icons/${iconDir}/${volume_icon}`">
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      audio: null,
      mute: false,
      css: {
        buttonWidth: "4rem",
        m_buttonWidth: "2.5rem",
        iconWidth: "38",
        m_iconWidth: "24",

        ...this.$baseComponents.musicButton
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--button-width': css.buttonWidth,
      }

      const mobile = {
        '--m-button-width': css.m_buttonWidth,
      }

      return { ...desktop, ...mobile }
    },
    volume_icon() {
      return this.mute ? "volume_off.svg" : "volume_up.svg"
    }
  },
  methods: {
    __init() {
      this.getAudio()
      this.playMusic(false)
    },
    getAudio() {
      this.audio = new Audio(this.$weddingData.musicSrc)
    },
    changeMusicState() {
      this.mute = !this.mute

      this.playMusic(this.mute)
    },
    playMusic(param) {
      if (!param) {
        if (typeof this.audio.loop == "boolean") {
          this.audio.loop = true
        } else {
          this.audio.addEventListener(
            "ended",
            () => {
              this.currentTime = 0
              this.play()
            },
            false
          );
        }
        this.audio.play()
      } else {
        this.audio.pause()
      }
    },
  },
  mounted() {
    this.__init()
  }
}
</script>

<style scoped>
  .music-btn-wrapper {
    right: 1.5rem;
    top: 1.5rem;
    opacity: 0.5 !important;
    position: fixed;
    width: var(--button-width);
    height: var(--button-width);
    border-radius: var(--button-width);
  }

  .music-btn-wrapper:hover {
    opacity: 1 !important;
    cursor: pointer;
  }
</style>

<style mobile scoped>
.mobile .music-btn-wrapper {
  width: var(--m-button-width);
  height: var(--m-button-width);
  border-radius: var(--m-button-width);
}
</style>