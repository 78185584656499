var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'd-flex align-items-center justify-content-center mt-18-5 w-rem-90-625 mx-auto': _vm.isDesktop,
    'mt-28-252 px-5': _vm.isMobile
  },style:(_vm.styles),attrs:{"id":"wish-section"}},[_c('div',{staticClass:"w-100",attrs:{"name":"content"}},[_c('section',{attrs:{"name":"title"}},[_c('Title',{attrs:{"text":"Ucapan untuk kami"}})],1),_c('section',{class:{
        'd-flex justify-content-center mt-7-5 pl-14': _vm.isDesktop,
        'mt-1-5': _vm.isMobile
      },attrs:{"name":"wish"}},[_c('div',{class:{
          'mr-6-252 pr-1-252 maxh-137-5 w-100': _vm.isDesktop,
          'maxh-rem-31': _vm.isMobile
        },attrs:{"name":"wishes"}},[(_vm.wishes.length)?[_vm._l((_vm.wishes),function(wish,index){return [_c('BorderedText',_vm._b({key:index,staticClass:"mb-3-752",attrs:{"boteStyle":"chat"}},'BorderedText',wish,false))]})]:[_c('p',{staticClass:"no-data text-center"},[_vm._v("Belum ada ucapan.")])]],2),_c('div',{class:{
          'ml-7-5 w-100': _vm.isDesktop,
          'pt-7-5 mt-7-5': _vm.isMobile
        },attrs:{"name":"form-input"}},[_c('Form',{class:{
            'pt-11-252 px-10 maxw-130 w-vw-50 h-126-252': _vm.isDesktop,
            'py-5 px-6': _vm.isMobile
          }},[_c('Input',{class:{
              'mb-7-5': _vm.isDesktop,
              'mb-2-5': _vm.isMobile
            },attrs:{"label":"Nama","placeholder":"Tulis Namamu..."},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('TextArea',{class:{
              'mb-7-5': _vm.isDesktop,
              'mb-3-752': _vm.isMobile
            },attrs:{"label":"Pesan","placeholder":"Tulis Pesanmu...","limit":"200","rows":"8"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}}),_c('div',{staticClass:"d-flex justify-content-center",class:{
              'mt-10-252': _vm.isDesktop,
              'mb-3-752': _vm.isMobile
            }},[_c('Button',{attrs:{"text":"Kirim Ucapan"},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}})],1)],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }