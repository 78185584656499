<template>
  <div class="payment-card-container d-flex">
    <div name="icon">
      <img :src="icon">
    </div>
    <div 
      name="detail" 
      class="ml-3-752"
      :style="{
        '--code-font-family': css.codeFontFamily,
        '--code-font-size': css.codeFontSize,
        '--code-font-color': css.codeFontColor,
        '--detail-font-family': css.detailFontFamily,
        '--detail-font-size': css.detailFontSize,
        '--detail-font-color': css.detailFontColor,
      }"
    >
      <p class="code d-flex align-items-center">{{ code }}<span class="d-flex ml-1"><img :src="`/icons/${iconDir}/copy.svg`"></span></p>
      <p class="detail">{{ detail }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "code", "detail"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir || "default",
      css: {
        codeFontFamily: "poppinsSB",
        codeFontSize: "1.25rem",
        codeFontColor: "#161616",
        detailFontFamily: "poppinsR",
        detailFontSize: "0.875rem",
        detailFontColor: "#161616",

        ...this.$sections.gift
      }
    }
  }
}
</script>

<style scoped>
  p.code {
    font-family: var(--code-font-family);
    font-size: var(--code-font-size);
    color: var(--code-font-color);
  }

  p.detail {
    font-family: var(--detail-font-family);
    font-size: var(--detail-font-size);
    color: var(--detail-font-color);
  }
</style>