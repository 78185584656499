<template>
  <div
    id="story-section"
    class="d-flex align-items-center justify-content-center mt-12-5"
  >
    <div name="content">
      <section name="title">
        <Title text="Kisah Kami" />
      </section>
      <section name="storyline">
        <StoryLine />
      </section>
    </div>
  </div>
</template>

<script>
import StoryLine from "@/components/sections/story/StoryLine"

export default {
  components: { StoryLine }
}
</script>

<style>

</style>