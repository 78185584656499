<template>
  <div 
    id="storyline-container"
    class="container" 
    v-view.once="startScroll"
    @mouseover="stopScroll"
    @mouseleave="startScroll"
    :style="{
      '--line-color': css.lineColor,
      '--icon-src': 'url(' + css.iconSrc + ')',
      '--icon-bg-color': css.iconBgColor,
      '--icon-border-color': css.iconBorderColor,
      '--card-border-color': css.cardBorderColor
    }"
  >
    <div class="timeline">
      <template v-for="(story, index) in $weddingData.storyLines">
        <div class="timeline-row" :key="index">
          <div class="timeline-content p-7-5">
            <p>{{ story.text }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      css: {
        lineColor: "#161616",
        iconSrc: `/icons/${this.$baseComponents.iconDir || 'default'}/story-icon.svg`,
        iconBgColor: "#161616",
        iconBorderColor: "#161616",
        cardBorderColor: "#161616",

        ...this.$sections.story
      },
      scrollFunction: null
    }
  },
  methods: {
    startScroll() {
      const element = document.getElementById("storyline-container")
      const maxScrollTop = element.scrollHeight - element.offsetHeight

      this.scrollFunction = setInterval(() => {
        element.scrollTop += 1

        if(element.scrollTop == maxScrollTop) element.scrollTop = 0
      }, 50)
    },
    stopScroll() {
      clearInterval(this.scrollFunction)
    }
  }
};
</script>

<style scoped>
#storyline-container {
  max-width: 97.125rem;
  max-height: 31.25rem;
  overflow-y: hidden;
}

.timeline {
  position: relative;
  background: transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  padding: 5rem;
  margin: 0 auto 1rem auto;
  overflow: hidden;
}

.timeline:after {
  content: "";
  position: absolute;
  top: 10%;
  left: 50%;
  margin-left: -2px;
  border-right: 2px solid var(--line-color);
  height: 100%;
  display: block;
}

.timeline-row {
  padding-left: 50%;
  position: relative;
  margin-bottom: 30px;
}

.timeline-row .timeline-time {
  position: absolute;
  right: 50%;
  top: 15px;
  text-align: right;
  margin-right: 20px;
  color: #bcd0f7;
  font-size: 1.5rem;
}

.timeline-row .timeline-time small {
  display: block;
  font-size: 0.8rem;
}

.timeline-row .timeline-content {
  position: relative;
  background: transparent;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border: 1px dashed var(--card-border-color);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.timeline-row .timeline-content:before {
  content: "";
  position: absolute;
  top: 20px;
  right: -67px;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  z-index: 10;
  background: var(--icon-src), var(--icon-bg-color);
  background-position: center;
  background-repeat: no-repeat;
  border: 2px solid var(--icon-border-color);
}

.timeline-row .timeline-content h4 {
  margin: 0 0 20px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 150%;
}

.timeline-row .timeline-content p {
  line-height: 150%;
}

.timeline-row .timeline-content i {
  font-size: 1.2rem;
  line-height: 100%;
  padding: 15px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
  background: #272e48;
  margin-bottom: 10px;
  display: inline-block;
}

.timeline-row .timeline-content .thumbs {
  margin-bottom: 20px;
  display: flex;
}

.timeline-row .timeline-content .thumbs img {
  margin: 5px;
  max-width: 60px;
}

.timeline-row .timeline-content .badge {
  color: #ffffff;
  background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
}

.timeline-row:nth-child(even) .timeline-content {
  margin-left: 40px;
  text-align: left;
}

.timeline-row:nth-child(even) .timeline-content:after {
  left: -8px;
  right: initial;
  border-bottom: 0;
  border-left: 0;
  transform: rotate(-135deg);
}

.timeline-row:nth-child(even) .timeline-content:before {
  left: -69px;
  right: initial;
}

.timeline-row:nth-child(odd) {
  padding-left: 0;
  padding-right: 50%;
}

.timeline-row:nth-child(odd) .timeline-time {
  right: auto;
  left: 50%;
  text-align: left;
  margin-right: 0;
  margin-left: 20px;
}

.timeline-row:nth-child(odd) .timeline-content {
  margin-right: 40px;
}

.timeline-row:nth-child(odd) .timeline-content:after {
  right: -8px;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
}

@media (max-width: 992px) {
  .timeline {
    padding: 15px;
  }

  .timeline:after {
    border: 0;
  }

  .timeline .timeline-row:nth-child(odd) {
    padding: 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content {
    margin: 0;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content:before {
    display: none;
  }

  .timeline .timeline-row:nth-child(odd) .timeline-content:after {
    display: none;
  }

  .timeline .timeline-row:nth-child(even) {
    padding: 0;
  }

  .timeline .timeline-row:nth-child(even) .timeline-time {
    position: relative;
    top: 0;
    left: 0;
    margin: 0 0 10px 0;
    text-align: left;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content {
    margin: 0;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content:before {
    display: none;
  }

  .timeline .timeline-row:nth-child(even) .timeline-content:after {
    display: none;
  }
}
</style>