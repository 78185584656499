<template>
  <div class="frame-container p2l3" v-viewer>
    <section name="p2l3" class="w-100 h-100 d-flex">
      <div class="image-container">
        <img width="312" height="450" :src="portraits[0]">
      </div>
      <div class="image-container mx-2">
        <img width="211" height="301" :src="portraits[1]">
        <img width="211" height="141" :src="landscapes[0]">
      </div>
      <div class="image-container">
        <img width="317" height="221" :src="landscapes[1]">
        <img width="317" height="221" :src="landscapes[2]">
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ["portraits", "landscapes"],
  data() {
    return {
      max_images: {
        portrait: 2,
        landscape: 3
      },
      current_index: {
        portrait: 0,
        landscape: 0
      }
    }
  },
  methods: {
    refreshImages() {
      setInterval(() => {
        let { max_images, current_index } = this

        if(max_images.portrait < (portraits.length - 1)) current_index.portrait += max_images.portrait
        else current_index.portrait = 0
        
        if(max_images.landscape < (landscapes.length - 1)) current_index.landscape += max_images.landscape
        else current_index.landscape = 0

        console.log("test interval", this.current_index)
      }, 3000)
    }
  },
  mounted() {
    this.refreshImages()
  }
}
</script>

<style scoped>
.p2l3 {
  width: 53.5rem;
  max-width: 53.5rem;
}
</style>