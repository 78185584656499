var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"radio-container",style:(_vm.styles)},[(_vm.label)?[_c('p',{class:{
        'mb-3-752': _vm.isDesktop,
        'mb-1-252': _vm.isMobile
      },style:({
        fontFamily: _vm.labelFontFamily,
        fontSize: _vm.isDesktop ? _vm.labelFontSize : _vm.m_labelFontSize,
        color: _vm.labelFontColor
      })},[_vm._v(" "+_vm._s(_vm.label)+" ")])]:_vm._e(),_c('div',{class:{ 'd-flex': _vm.isDesktop }},[_vm._l((_vm.radio_items),function(item){return [_c('div',{key:item.id},[_c('div',{staticClass:"radio-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedValue),expression:"selectedValue"}],attrs:{"type":"radio","id":item.id,"name":_vm.name},domProps:{"value":item.value,"checked":_vm._q(_vm.selectedValue,item.value)},on:{"change":function($event){_vm.selectedValue=item.value}}}),_c('label',{style:({ fontFamily: _vm.fontFamilyLabel }),attrs:{"for":item.id}},[_vm._v(" "+_vm._s(item.label)+" ")])])])]})],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }