<template>
  <div class="input-container">
    <p
      :class="{
        'label mb-3-752': isDesktop,
        'mb-1': isMobile
      }"
      :style="{
        fontFamily: fontFamilyLabel, 
        fontSize: isDesktop ? fontSizeLabel : m_fontSizeLabel, 
        color: labelColor
      }"
    >
      {{ label }}
    </p>
    <textarea
      v-bind="$attrs"
      @input="e => $emit('input', e.target.value)"
      class="w-100 resize-none"
      :rows="rows || '4'"
      :style="{
        '--placeholder-color': phColor,
        '--focus-border-color': fbColor,
        fontFamily: fontFamilyInput,
        fontSize: isDesktop ? fontSizeInput : m_fontSizeInput,
        borderColor
      }"
      :maxlength="limit"
    />
    <template v-if="limit">
      <p
        class="counter text-right"
        :style="{
          fontFamily: countFontFamily, 
          fontSize: isDesktop ? countFontSize : m_countFontSize, 
          color: countFontColor
        }"
      >
        {{ value.length }} / {{ limit }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  props: [
    "label",
    "labelColor", 
    "rows", 
    "placeholderColor", 
    "borderColor", 
    "focusBorderColor", 
    "fontFamilyLabel", 
    "fontSizeLabel", 
    "fontFamilyInput", 
    "fontSizeInput", 
    "limit", 
    "countFontFamily", 
    "countFontSize", 
    "countFontColor",
    "m_fontSizeLabel",
    "m_fontSizeInput",
    "m_countFontSize"
  ],
  data() {
    return {
      value: "",
      phColor: this.placeholderColor || "#B1B1B1",
      fbColor: this.focusBorderColor || this.borderColor || "#CCCCCC"
    }
  }
}
</script>

<style scoped>
  p {
    font-family: poppinsSB;
    font-size: 1rem;
  }

  p.counter {
    font-family: opensansR;
    font-size: 0.875rem;
    color: #161616;
  }

  textarea {
    font-family: opensansR;
    font-size: 0.875rem;
    color: #956464;
    padding: 0.75rem 1.25rem;
    border: 1px solid;
    border-color: #CCCCCC;
    border-radius: 0.938rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  textarea::placeholder {
    color: var(--placeholder-color);
  }

  textarea:focus {
    outline: none;
    border-color: var(--focus-border-color) !important;
  }
</style>