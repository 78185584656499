import { render, staticRenderFns } from "./BaseRadio.vue?vue&type=template&id=1bb9203b&scoped=true&"
import script from "./BaseRadio.vue?vue&type=script&lang=js&"
export * from "./BaseRadio.vue?vue&type=script&lang=js&"
import style0 from "./BaseRadio.vue?vue&type=style&index=0&id=1bb9203b&scoped=true&lang=css&"
import style1 from "./BaseRadio.vue?vue&type=style&index=1&id=1bb9203b&mobile=true&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bb9203b",
  null
  
)

export default component.exports