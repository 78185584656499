// Base Vue
import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'

/// Mixins
import { deviceAgent, deviceWindow, alert } from '@/config/mixins'

// VueMq
import VueMq from 'vue-mq'

// VueCookie
import VueCookie from 'vue-cookie'

// VueScrollTo
import VueScrollTo from 'vue-scrollto'

// VueCheckView
import checkView from 'vue-check-view'

// V-Viewer
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'

// Animate on Scroll
import AOS from "aos"
import "aos/dist/aos.css"

// VueSweetalert2
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// Global Apis
import GlobalApis from "@/config/global-apis"

// Global Predined
import GlobalResources from "@/config/global-resources"

// Global Variables
import GlobalVariables from "@/config/global-variables"

Vue.config.productionTip = false

Vue.use(GlobalApis)

Vue.use(new AOS.init({
  offset: 50,
  duration: 1000,
  delay: 100
}))

Vue.use(checkView)

Vue.use(VueSweetalert2)

Vue.use(VueCookie)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 900,
    desktop: Infinity
  }
})

Vue.use(VueScrollTo, {
  duration: 800,
  easing: "ease"
})

Vue.use(Viewer, {
  defaultOptions: {
    button: true,
    navbar: true,
    title: false,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: false,
    keyboard: true
  }
})

Vue.use(GlobalResources)

Vue.use(GlobalVariables)

Vue.mixin(deviceAgent)
Vue.mixin(deviceWindow)
Vue.mixin(alert)

new Vue({
  router,
  render: h =>  h(App)
}).$mount('#app')