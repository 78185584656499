<template>
  <div id="floating-button">
    <div class="floating-container d-flex justify-content-evenly">
      <template v-for="(icon, index) in icons">
        <img class="menu-icon" :src="`/icons/${iconDir}/${activeMenu == icon.menu ? icon.activeSrc : icon.src}`" :key="index" @click="scrollTo(icon.menu)">
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['viewed_menu'],
  data() {
    return {
      activeMenu: "opening-section",
      iconDir: this.$baseComponents.icon.dir,
      icons: [
        {
          src: "wedding-couple.svg",
          activeSrc: "wedding-couple-active.svg",
          menu: "opening-section",
        },
        {
          src: "wedding-day.svg",
          activeSrc: "wedding-day-active.svg",
          menu: "time-section"
        },
        {
          src: "wedding-arch.svg",
          activeSrc: "wedding-arch-active.svg",
          menu: "location-section"
        },
        {
          src: "wedding-planning.svg",
          activeSrc: "wedding-planning-active.svg",
          menu: "rsvp-section"
        },
        {
          src: "picture.svg",
          activeSrc: "picture-active.svg",
          menu: "gallery-section"
        },
      ]
    }
  },
  methods: {
    scrollTo(menu_id) {
      const menu = document.getElementById(menu_id)

      this.activeMenu = menu_id
      
      menu.scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
}
</script>

<style mobile scoped>
  .mobile #floating-button {
    position: fixed;
    bottom: 40px;
    right: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
    padding-left: 2.188rem;
    padding-right: 2.188rem;
  }

  .mobile .floating-container {
    background-color: #fff;
    border-radius: 1.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }

  .mobile .menu-icon:hover {

  }
</style>