<template>
  <div class="input-container">
    <p
      :class="{
        'mb-3-752': isDesktop,
        'mb-1': isMobile
      }"
      :style="{
        fontFamily: fontFamilyLabel,
        fontSize: isDesktop ? fontSizeLabel : m_fontSizeLabel,
        color: labelColor
      }"
    >
      {{ label }}
    </p>
    <input
      @input="e => $emit('input', e.target.value)"
      v-bind="$attrs"
      class="w-100"
      :style="{
        '--placeholder-color': phColor,
        '--focus-border-color': fbColor,
        fontFamily: fontFamilyInput,
        fontSize: isDesktop ? fontSizeInput : m_fontSizeInput,
        borderColor
      }"
    />
  </div>
</template>

<script>
export default {
  props: ["label", "labelColor", "placeholderColor", "borderColor", "focusBorderColor", "fontFamilyLabel", "fontSizeLabel", "fontFamilyInput", "fontSizeInput", "m_fontSizeLabel", "m_fontSizeInput"],
  data() {
    return {
      phColor: this.placeholderColor || "#B1B1B1",
      fbColor: this.focusBorderColor || this.borderColor || "#CCCCCC"
    }
  }
}
</script>

<style scoped>
  p {
    font-family: poppinsSB;
    font-size: 1rem;
  }

  input {
    font-family: opensansR;
    font-size: 0.875rem;
    color: #956464;
    padding: 0.75rem 1.25rem;
    border: 1px solid;
    border-color: #CCCCCC;
    border-radius: 0.938rem;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }

  input::placeholder {
    color: var(--placeholder-color);
  }

  input:focus {
    outline: none;
    border-color: var(--focus-border-color) !important;
  }
</style>