var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"input-container"},[_c('p',{class:{
      'label mb-3-752': _vm.isDesktop,
      'mb-1': _vm.isMobile
    },style:({
      fontFamily: _vm.fontFamilyLabel, 
      fontSize: _vm.isDesktop ? _vm.fontSizeLabel : _vm.m_fontSizeLabel, 
      color: _vm.labelColor
    })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('textarea',_vm._b({staticClass:"w-100 resize-none",style:({
      '--placeholder-color': _vm.phColor,
      '--focus-border-color': _vm.fbColor,
      fontFamily: _vm.fontFamilyInput,
      fontSize: _vm.isDesktop ? _vm.fontSizeInput : _vm.m_fontSizeInput,
      borderColor: _vm.borderColor
    }),attrs:{"rows":_vm.rows || '4',"maxlength":_vm.limit},on:{"input":function (e) { return _vm.$emit('input', e.target.value); }}},'textarea',_vm.$attrs,false)),_vm._v(" "),(_vm.limit)?[_c('p',{staticClass:"counter text-right",style:({
        fontFamily: _vm.countFontFamily, 
        fontSize: _vm.isDesktop ? _vm.countFontSize : _vm.m_countFontSize, 
        color: _vm.countFontColor
      })},[_vm._v(" "+_vm._s(_vm.value.length)+" / "+_vm._s(_vm.limit)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }