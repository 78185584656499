<template>
  <div
    id="footer-section"
    class="d-flex align-items-center justify-content-center"
    :class="{
      'mt-13 h-rem-21-875': isDesktop,
      'pb-30 pt-18': isMobile
    }"
  >
    <div name="content" class="w-100 text-center">
      <img 
        :width="isDesktop ? css.footerIconWidth : css.m_footerIconWidth" 
        src="https://github.com/momentoprojectsid/storage/raw/master/momento_logo.png"
      >
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      css: {
        ...this.$sections.footer
      }
    }
  }
}
</script>