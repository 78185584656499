const deviceAgent = {
  data() {
    return {
      device: typeof window != "undefined" ? (window.innerWidth <=900 ? "mobile" : "desktop") : null,
    }
  },
  computed: {
    isDesktop() {
      return this.device == "desktop" ? true : false
    },
    isMobile() {
      return this.device == "mobile" ? true : false
    }
  },
  methods: {
    onResize() {
        this.device = window.innerWidth <=992 ? "mobile" : "desktop"
    },
  },
  mounted() {
    if(typeof window != "undefined") window.addEventListener('resize', this.onResize)
  },
  beforeDestroy() { 
    if(typeof window != "undefined") window.removeEventListener('resize', this.onResize)
  },
}

const deviceWindow = {
  data() {
    return {
      windowWidth: window.innerWidth
    }
  }
}

const alert = {
  methods: {
    alertSuccess(message) {
      this.$swal({
        icon: "success",
        html: `<h5>${this.$weddingData.brideNickName} & ${this.$weddingData.groomNickName} Wedding</h5><h6>${message}</h6>`,
        showConfirmButton: true,
        confirmButtonColor: "#00916E",
        iconColor: "#A4D3A7",
      })
    },
    alertFail(message = '') {
      this.$swal({
        icon: "error",
        html: `<h6>${message || 'Terjadi kesalahan. Mohon ulangi beberapa menit lagi :('}</h6>`,
        showConfirmButton: true,
      })
    }
  }
}

export { deviceAgent, deviceWindow, alert }